import React, { useEffect, useState } from "react"

import { Fade } from "react-reveal"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PromotionCode = props => {
  const { handleCheckCode, handleReleaseCode, promotionCode } = props

  const [rawPromotionCode, setRawPromotionCode] = useState("")

  const [promotionCodeLocked, setPromotionCodeLocked] = useState(false)

  const onCheckCodeClick = event => {
    event.preventDefault()
    handleCheckCode(rawPromotionCode)
  }

  const onReleaseCodeClick = event => {
    event.preventDefault()
    handleReleaseCode()
  }

  const onCodeTextChange = event => {
    event.preventDefault()
    setRawPromotionCode(event.target.value)
  }

  useEffect(() => {
    if (promotionCode && promotionCode != "") {
      setPromotionCodeLocked(true)
      setRawPromotionCode(promotionCode)
    } else {
      setPromotionCodeLocked(false)
      setRawPromotionCode("")
    }
  }, [promotionCode])

  const lockedPromotionCodeContent = (
    <Fade>
      <Box
        display="flex"
        flexWrap="wrap"
        my={5}
        alignItems="center"
        justifyContent="center"
      >
        <Box component="p" m={1}>
          <FormattedMessage
            id="promotionCode_lblYourCode"
            defaultMessage="Twój kod zniżkowy:"
          />
          <b>{promotionCode}</b>
        </Box>
        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            className="package-code-submit"
            onClick={onReleaseCodeClick}
          >
            <FormattedMessage
              id="promotionCode_btnUseAnotherCode"
              defaultMessage="Skorzystaj z innego"
            />
          </Button>
        </Box>
      </Box>
    </Fade>
  )

  const emptyPromotionCodeContent = (
    <Box display="flex" flexDirection="column" my={5} alignItems="center">
      <FormattedMessage
        id="promotionCode_lblUserPromotionCode"
        defaultMessage="Posiadasz kod zniżkowy? Użyj go by uzyskać rabat"
      />
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box m={1}>
          <TextField
            name="code"
            variant="outlined"
            value={rawPromotionCode ? rawPromotionCode : ""}
            onChange={onCodeTextChange}
            fullWidth
          />
        </Box>

        <Box m={1}>
          <Button
            color="primary"
            variant="contained"
            className="package-code-submit"
            onClick={onCheckCodeClick}
          >
            <FormattedMessage
              id="promotionCode_btnUsePromotionCode"
              defaultMessage="Użyj"
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )

  if (promotionCodeLocked) {
    return lockedPromotionCodeContent
  } else {
    return emptyPromotionCodeContent
  }
}

export default PromotionCode
