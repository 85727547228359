import React, { useState, useEffect } from "react"
import PatientForm from "./patient-form/patientForm"
import ShipmentForm from "./shipment-form/shipmentForm"
import PackageForm from "./package-form/packageForm"
import OrderSummary from "./summary/orderSummary"
import { withSnackbar } from "react-simple-snackbar"
import snackBarSettings from "../../components/snackBarSettings"
import { trackPromise } from "react-promise-tracker"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import PregnancyDateForm from "./pregnancy-date-form/pregnancyDateForm"
import OrderStepper from "./order-stepper"

import { useQueryParam, StringParam } from "use-query-params"
import { navigate } from "gatsby"

const axios = require(`axios`)

const OrderBanner = props => {
  const intl = useIntl()

  const { openSnackbar } = props

  const [packages, setPackages] = useState([])
  const [shippingMethods, setShippingMethods] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])

  const [page, setPage] = useState(0)

  const [prefilledCode, setPrefilledCode] = useQueryParam("code", StringParam)

  const [pregnancyDateFormValues, setPregnancyDateFormValues] = useState({
    estimated_childbirth_date: null,
  })

  const [packageFormValues, setPackageFormValues] = useState({
    package_id: null,
    code: null,
  })

  const [shipmentFormValues, setShipmentFormValues] = useState({
    shipping_method_id: "",
    payment_method_id: "",
    delivery_date: null,
    address_details: {
      country: "",
      voivodeship: "",
      city: "",
      postal_code: "",
      street: "",
      house_no: "",
      apartment_no: "",
      additional_info: "",
    },
  })

  const [patientFormValues, setPatientFormValues] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    birth_date: null,
    language_cd: "",
    environment: {
      past_pregnancies: false,
      weight: "",
      height: "",
      children_no: "",
      medication: "",
      risk_factors: "",
      info: "",
    },
    emergency_person: {
      name: "",
      surname: "",
      email: "",
      kinship: "",
      phone: "",
    },
  })

  const [order, setOrder] = useState({ price: 0 })

  useEffect(() => {
    if (prefilledCode) {
      trackPromise(
        axios
          .get(
            `${process.env.GATSBY_API_URL}/user/registration/check/code?code=${prefilledCode}`
          )
          .then(res => {
            if (res.data.valid) {
              setPackageFormValues({
                ...packageFormValues,
                code: prefilledCode,
              })
            }
          })
      )
    }
  }, [])

  const handlePregnancyDateFormSubmit = (formValues, castValues) => {
    const params = {
      estimated_childbirth_date: castValues.estimated_childbirth_date,
      code: packageFormValues.code,
    }

    return trackPromise(
      axios
        .get(`${process.env.GATSBY_API_URL}/subscription/order/packages`, {
          params,
        })
        .then(response => {
          setPackages(response.data.data)
          setPackageFormValues({ ...packageFormValues, package_id: null })
          setPregnancyDateFormValues(
            Object.assign({
              estimated_childbirth_date: formValues.estimated_childbirth_date,
            })
          )
          setOrder(
            Object.assign(order, {
              environment: {
                estimated_childbirth_date: castValues.estimated_childbirth_date,
              },
            })
          )

          window.dataLayer.push({
            event: "estimated_childbirth_date_filled",
            estimatedChildbirthDate: castValues.estimated_childbirth_date,
          })

          nextPage()
          window.scrollTo(0, 0)
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgPackagesError",
              defaultMessage: "Nie udało się pobrać listy pakietów...",
            }),
            4000
          )
        )
    )
  }

  const handleCheckCode = rawCode => {
    trackPromise(
      axios
        .get(
          `${process.env.GATSBY_API_URL}/user/registration/check/code?code=${rawCode}`
        )
        .then(res => {
          if (res.data.valid) {
            const params = {
              estimated_childbirth_date:
                order.environment.estimated_childbirth_date,
              code: rawCode,
            }

            axios
              .get(
                `${process.env.GATSBY_API_URL}/subscription/order/packages`,
                { params }
              )
              .then(response => {
                setPackages(response.data.data)
                setPackageFormValues({
                  package_id: null,
                  code: rawCode,
                })
                window.scrollTo(0, 0)
              })
              .catch(error =>
                openSnackbar(
                  intl.formatMessage({
                    id: "orderBanner_msgPackagesError",
                    defaultMessage: "Nie udało się pobrać listy pakietów...",
                  }),
                  4000
                )
              )
          } else {
            openSnackbar(
              intl.formatMessage({
                id: "orderBanner_msgCodeError",
                defaultMessage: "Kod zniżkowy nieprawidłowy",
              }),
              2500
            )
          }
        })
        .catch(error => {
          if (error.response) {
            openSnackbar(
              intl.formatMessage({
                id: "orderBanner_msgCodeError",
                defaultMessage: "Kod zniżkowy nieprawidłowy",
              }),
              2500
            )
          }
        })
    )
  }

  const handleReleaseCode = () => {
    setPackageFormValues({
      package_id: null,
      code: null,
    })
  }

  const handlePackageFormSubmit = (formValues, castValues) => {
    setPackageFormValues(Object.assign(packageFormValues, formValues))
    setOrder(Object.assign(order, castValues))

    const selectedPackage = packages.find(p => p.id === order.package_id)

    const params = {
      code: packageFormValues.code,
    }

    const shippingMethodsRequest = axios.get(
      `${process.env.GATSBY_API_URL}/subscription/order/shipping-methods`,
      {
        params,
      }
    )

    const paymentMethodsRequest = axios.get(
      `${process.env.GATSBY_API_URL}/subscription/order/payment-methods`,
      {
        params,
      }
    )

    return trackPromise(
      axios
        .all([shippingMethodsRequest, paymentMethodsRequest])
        .then(
          axios.spread((...responses) => {
            const responseShippingMethods = responses[0].data.data
            const responsePaymentMethods = responses[1].data.data

            setShippingMethods(responseShippingMethods)
            setPaymentMethods(responsePaymentMethods)
            setShipmentFormValues({
              ...shipmentFormValues,
              shipping_method_id: responseShippingMethods[0].id.toString(),
              payment_method_id: responsePaymentMethods[0].id.toString(),
            })

            placePackageInDataLayer()

            nextPage()
            window.scrollTo(0, 0)
          })
        )
        .catch(errors => {
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgShippingMethodsError",
              defaultMessage: "Nie udało się pobrać danych wysyłkowych...",
            }),
            4000
          )
        })
    )
  }

  const handleShipmentFormSubmit = (formValues, castValues) => {
    setShipmentFormValues(Object.assign(shipmentFormValues, formValues))
    setOrder(Object.assign(order, castValues))

    nextPage()
    window.scrollTo(0, 0)
  }

  const handlePatientFormSubmit = (formValues, castValues) => {
    setPatientFormValues(Object.assign(patientFormValues, formValues))

    setOrder(
      Object.assign(order, castValues, {
        environment: {
          ...castValues.environment,
          estimated_childbirth_date:
            order.environment.estimated_childbirth_date,
        },
      })
    )

    return calculateOrderPrice()
  }

  const calculateOrderPrice = () => {
    const params = {
      estimated_childbirth_date: order.environment.estimated_childbirth_date,
      code: order.code,
      package_id: order.package_id,
      shipping_method_id: order.shipping_method_id,
    }

    return trackPromise(
      axios
        .get(`${process.env.GATSBY_API_URL}/subscription/order/calculate`, {
          params,
        })
        .then(response => {
          setOrder(Object.assign(order, response.data))
          nextPage()
          window.scrollTo(0, 0)
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgPriceError",
              defaultMessage: "Nie udało się obliczyć zamówienia...",
            }),
            4000
          )
        )
    )
  }

  const handlePlaceOrder = () => {
    trackPromise(
      axios
        .post(`${process.env.GATSBY_API_URL}/user/registration`, order)
        .then(response => {
          if (response.data.payment_url) {
            openSnackbar(
              intl.formatMessage({
                id: "orderBanner_msgOrderSuccess",
                defaultMessage:
                  "Zamówienie złożone pomyślnie za chwilę nastąpi przekierowanie do płatności...",
              }),
              4000
            )

            placeOrderInDataLayer()

            setTimeout(() => {
              window.location.href = response.data.payment_url
            }, 2500)
          } else {
            navigate("/successful-order")
          }
        })
        .catch(error =>
          openSnackbar(
            intl.formatMessage({
              id: "orderBanner_msgOrderError",
              defaultMessage: "Nie udało się złożyć zamówienia...",
            }),
            4000
          )
        )
    )
  }

  const placePackageInDataLayer = () => {
    const selectedPackage = packages.find(p => p.id === order.package_id)

    if (selectedPackage) {
      window.dataLayer.push({
        event: "package_selected",
        packageId: selectedPackage.id,
        packageName: selectedPackage.name,
        packagePrice:
          order.code != null && selectedPackage.discount_price
            ? selectedPackage.discount_price
            : selectedPackage.price,
        packageCurrency: selectedPackage.currency,
        promotionCode: order.code,
      })
    }
  }

  const placeOrderInDataLayer = () => {
    const selectedPackage = packages.find(p => p.id === order.package_id)

    if (selectedPackage) {
      window.dataLayer.push({
        event: "purchase",
        packageId: selectedPackage.id,
        packageName: selectedPackage.name,
        packagePrice:
          order.code != null && selectedPackage.discount_price
            ? selectedPackage.discount_price
            : selectedPackage.price,
        packageCurrency: selectedPackage.currency,
        promotionCode: order.code,
        orderPrice: order.price,
        orderCurrency: order.currency,
      })
    }
  }

  const nextPage = () => {
    setPage(page + 1)
  }

  const previousPage = () => {
    setPage(page - 1)
    window.scrollTo(0, 0)
  }

  return (
    <Box
      className="order-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container disableGutters={true}>
        <Box component="h2" textAlign="center">
          <FormattedMessage
            id="orderBanner_title"
            defaultMessage="Zamówienie"
          />
        </Box>
        <OrderStepper currentOrderStep={page}></OrderStepper>
        {page === 0 && (
          <PregnancyDateForm
            handleSubmitting={handlePregnancyDateFormSubmit}
            values={pregnancyDateFormValues}
          ></PregnancyDateForm>
        )}
        {page === 1 && (
          <PackageForm
            handleCheckCode={handleCheckCode}
            handleReleaseCode={handleReleaseCode}
            handleSubmitting={handlePackageFormSubmit}
            handleAbort={previousPage}
            values={packageFormValues}
            packages={packages}
          ></PackageForm>
        )}
        {page === 2 && (
          <ShipmentForm
            shippingMethods={shippingMethods}
            paymentMethods={paymentMethods}
            handleSubmitting={handleShipmentFormSubmit}
            handleAbort={previousPage}
            values={shipmentFormValues}
          ></ShipmentForm>
        )}
        {page === 3 && (
          <PatientForm
            handleSubmitting={handlePatientFormSubmit}
            handleAbort={previousPage}
            values={patientFormValues}
          ></PatientForm>
        )}
        {page === 4 && (
          <OrderSummary
            order={order}
            packages={packages}
            handleAbort={previousPage}
            handleSubmitting={handlePlaceOrder}
          ></OrderSummary>
        )}
      </Container>
    </Box>
  )
}

export default withSnackbar(OrderBanner, snackBarSettings)
