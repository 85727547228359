import React from "react"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import packageFormSchema from "./packageFormSchema"
import Package from "./package"
import CircularButton from "../../../components/circularButton"
import PromotionCode from "./promotionCode"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PackageForm = props => {
  const {
    handleCheckCode,
    handleReleaseCode,
    handleSubmitting,
    handleAbort,
    values,
    packages,
  } = props

  return (
    <Formik
      validationSchema={packageFormSchema}
      onSubmit={(values, actions) => {
        handleSubmitting(values, packageFormSchema.cast(values))
        actions.setSubmitting(false)
      }}
      enableReinitialize
      initialValues={values}
    >
      {({ handleChange, handleBlur, isSubmitting, values }) => (
        <Box component={Form} py={3} overflow="hidden">
          <Grid
            container
            wrap="wrap"
            alignItems="stretch"
            justifyContent="center"
            spacing={2}
          >
            {packages.map((element, index) => {
              return (
                <Grid item>
                  <Package
                    name="package_id"
                    packageName={element.name}
                    description={element.description}
                    id={`package${element.id}`}
                    price={element.price}
                    discountPrice={element.discount_price}
                    discount={values.code != null && element.discount_price}
                    currency={element.currency}
                    value={element.id}
                    checked={values.package_id == element.id}
                    recommended={element.recommended}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  ></Package>
                </Grid>
              )
            })}
          </Grid>
          <PromotionCode
            promotionCode={values.code}
            handleCheckCode={handleCheckCode}
            handleReleaseCode={handleReleaseCode}
          ></PromotionCode>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mx={3}
          >
            <CircularButton
              onClick={handleAbort}
              direction="left"
            ></CircularButton>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="packageForm_btnNext"
                defaultMessage="Dalej"
              />
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

PackageForm.propTypes = {
  handleSubmitting: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default PackageForm
