import React from "react"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { FormControlLabel, Radio } from "@material-ui/core"
import { Field } from "formik"
import { RadioGroup } from "formik-material-ui"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const PaymentMethodFormPart = formikProps => {
  const {
    paymentMethods,
    handleChange,
    handleBlur,
    values,
    errors,
  } = formikProps

  return (
    <>
      <Box mb={3}>
        <h4>
          <FormattedMessage
            id="paymentForm_title"
            defaultMessage="Metoda płatności"
          />
        </h4>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Field component={RadioGroup} name="payment_method_id">
            {paymentMethods.map((element, index) => {
              return (
                <Box ml={2}>
                  <FormControlLabel
                    value={`${element.id}`}
                    control={<Radio />}
                    label={`${element.name}`}
                  />
                </Box>
              )
            })}
          </Field>
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentMethodFormPart
