import React, { useMemo } from "react"
import { Formik, Form } from "formik"
import { DatePicker } from "formik-material-ui-pickers"
import { format, addMonths } from "date-fns"
import { Field } from "formik"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import CardContent from "@material-ui/core/CardContent"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import * as yup from "yup"
import PropTypes from "prop-types"
import bannerLogo from "../../../assets/calendar.png"
import Fade from "react-reveal/Fade"

const PregnancyDateForm = props => {
  const intl = useIntl()
  const { handleSubmitting, values } = props

  const validationSchema = useMemo(
    () =>
      yup.object({
        estimated_childbirth_date: yup
          .string()
          .required(
            intl.formatMessage({
              id: "pregnancyDateForm_requiredFieldChildbirthDate",
              defaultMessage: "Wprowadź przewidywaną datę porodu",
            })
          )
          .transform((value, originalValue) =>
            originalValue ? format(new Date(value), "yyyy-MM-dd") : null
          )
          .nullable(),
      }),
    [intl]
  )

  const renderInput = props => (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
        onClick={props.onClick}
      >
        <Grid item xs={3}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxSizing="border-box"
          >
            <div className="counter-part-box">
              <h4>
                {props.value ? format(new Date(props.value), "yyyy") : "YYYY"}
              </h4>
            </div>
          </Box>
        </Grid>

        <Grid item xs={1}>
          <Box textAlign="center">
            <h4>/</h4>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxSizing="border-box"
          >
            <div className="counter-part-box">
              <h4>
                {props.value ? format(new Date(props.value), "MM") : "MM"}
              </h4>
            </div>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box textAlign="center">
            <h4>/</h4>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxSizing="border-box"
          >
            <div className="counter-part-box">
              <h4>
                {props.value ? format(new Date(props.value), "dd") : "DD"}
              </h4>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  )

  return (
    <Box my={6}>
      <Card className="order-card">
        <CardContent>
          <Grid container>
            <Grid item className="banner-left" xs={12} lg={6}>
              <Box mb={6} textAlign="center">
                <h2>
                  <FormattedMessage
                    id="pregnancyDateForm_title"
                    defaultMessage="Twoja data porodu"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="pregnancyDateForm_description"
                    defaultMessage="To dla nas bardzo ważne, na podstawie daty Twojego porodu wyznaczymy najlepsze pakiety dla Ciebie"
                  />
                </p>
              </Box>
              <Formik
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  handleSubmitting(
                    values,
                    validationSchema.cast(values)
                  ).finally(() => actions.setSubmitting(false))
                }}
                initialValues={values}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Box py={1} maxWidth="600px" margin="auto">
                      <Field
                        component={DatePicker}
                        label={intl.formatMessage({
                          id: "pregnancyDateForm_lblFieldChildbirthDate",
                          defaultMessage: "Przewidywana data porodu",
                        })}
                        name="estimated_childbirth_date"
                        format="yyyy-MM-dd"
                        disablePast={true}
                        maxDate={addMonths(new Date(), 9)}
                        DialogProps={{ disableScrollLock: true }}
                        TextFieldComponent={renderInput}
                        okLabel={intl.formatMessage({
                          id: "calendar_lblOk",
                          defaultMessage: "OK",
                        })}
                        cancelLabel={intl.formatMessage({
                          id: "calendar_lblCancel",
                          defaultMessage: "Cancel",
                        })}
                        fullWidth
                      />
                    </Box>
                    <Box textAlign="right" mt={8} mr={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <FormattedMessage
                          id="pregnancyDateForm_btnNext"
                          defaultMessage="Dalej"
                        />
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid item className="banner-right" xs={12} lg={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Fade fraction={0.75}>
                  <img src={bannerLogo} alt="Woman with calendar"></img>
                </Fade>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
PregnancyDateForm.propTypes = {
  handleSubmitting: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default PregnancyDateForm
