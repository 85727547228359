import React, { useEffect } from "react"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { FormControlLabel, Radio } from "@material-ui/core"
import { Field } from "formik"
import { RadioGroup } from "formik-material-ui"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const ShippingMethodFormPart = props => {
  const { shippingMethods } = props

  return (
    <>
      <Box mb={3}>
        <h4>
          <FormattedMessage
            id="shippingForm_title"
            defaultMessage="Metoda wysyłki"
          />
        </h4>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Field component={RadioGroup} name="shipping_method_id">
            {shippingMethods.map((element, index) => {
              return (
                <Box ml={2}>
                  <FormControlLabel
                    value={`${element.id}`}
                    control={<Radio />}
                    label={`${element.name}, ${element.price} ${element.currency}`}
                  />
                </Box>
              )
            })}
          </Field>
        </Grid>
      </Grid>
    </>
  )
}

export default ShippingMethodFormPart
