import React from "react"
import PropTypes from "prop-types"

const CircularButton = ({ onClick, direction, className }) => {
  return (
    <>
      <a className={"circular-nav-btn " + className} onClick={onClick}>
        {direction == "right" ? (
          <svg height="100px" width="100px">
            <g>
              <line x1="30" x2="60" y1="50" y2="50"></line>
              <polyline points=" 55,45 60,50 55,55"></polyline>
              <circle cx="50" cy="50" r="30"></circle>
            </g>
          </svg>
        ) : (
          <svg height="100px" width="100px">
            <g>
              <line x1="40" x2="70" y1="50" y2="50"></line>
              <polyline points=" 45,45 40,50 45,55"></polyline>
              <circle cx="50" cy="50" r="30"></circle>
            </g>
          </svg>
        )}
      </a>
    </>
  )
}

CircularButton.propTypes = {
  direction: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

CircularButton.defaultProps = {
  direction: `right`,
  className: ``,
}

export default CircularButton
