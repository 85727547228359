import React from "react"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Fade from "react-reveal/Fade"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const Package = props => {
  const {
    packageName,
    id,
    name,
    description,
    price,
    discountPrice,
    discount,
    currency,
    value,
    recommended,
    checked,
    handleChange,
    handleBlur,
  } = props

  const discountPriceContent = (
    <>
      <Fade>
        <p className="package-pricing">
          <span className="old-price">
            <strike>{price}</strike>
          </span>
          <span className="new-price">
            {discountPrice}
            <sup>{currency}</sup>
            <sub>
              <FormattedMessage id="package_brutto" defaultMessage="brutto" />
            </sub>
          </span>
        </p>
      </Fade>
    </>
  )

  const priceContent = (
    <p className="package-pricing">
      {price}
      <sup>{currency}</sup>
      <sub>
        <FormattedMessage id="package_brutto" defaultMessage="brutto" />
      </sub>
    </p>
  )

  return (
    <Paper
      className={`package-card ${checked ? "selected" : ""} ${
        recommended ? "recommended" : ""
      }`}
      square={true}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
        p={5}
        height="100%"
        boxSizing="border-box"
        className="package-content"
      >
        {recommended && (
          <div className="ribbon ribbon-top-right">
            <span>Oferta specjalna</span>
          </div>
        )}

        <Fade>
          <Box
            className="package-title"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <h2>{packageName}</h2>
          </Box>
        </Fade>
        <div>
          <Box component="p" className="package-description" my={3}>
            {description}
          </Box>
          {discount ? discountPriceContent : priceContent}
          <div className="package-select-radio">
            <input
              type="radio"
              id={id}
              name={name}
              value={value}
              checked={checked}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor={id}>
              <FormattedMessage
                id="package_btnSelect"
                defaultMessage="Wybierz"
              />
            </label>
          </div>
        </div>
      </Box>
    </Paper>
  )
}

export default Package
