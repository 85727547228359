import React, { useMemo } from "react"
import * as yup from "yup"
import PropTypes from "prop-types"
import AddressDetailsFormPart from "./addressDetailsFormPart"
import { Truck } from "react-bootstrap-icons"
import ShippingMethodFormPart from "./shippingMethodFormPart"
import PaymentMethodFormPart from "./paymentMethodFormPart"
import CircularButton from "../../../components/circularButton"

import { Formik, Form } from "formik"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { format } from "date-fns"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

const ShipmentForm = props => {
  const intl = useIntl()
  const {
    shippingMethods,
    paymentMethods,
    handleSubmitting,
    handleAbort,
    values,
  } = props

  const validationSchema = useMemo(
    () =>
      yup.object({
        address_details: yup
          .object()
          .shape({
            country: yup.string().required(
              intl.formatMessage({
                id: "addressDetailsForm_requiredFieldCountry",
                defaultMessage: "Wprowadź kraj",
              })
            ),
            voivodeship: yup.string().required(
              intl.formatMessage({
                id: "addressDetailsForm_requiredFieldVoivodeship",
                defaultMessage: "Wprowadź województwo",
              })
            ),
            city: yup.string().required(
              intl.formatMessage({
                id: "addressDetailsForm_requiredFieldCity",
                defaultMessage: "Wprowadź miasto",
              })
            ),
            postal_code: yup.string().required(
              intl.formatMessage({
                id: "addressDetailsForm_requiredFieldPostalCode",
                defaultMessage: "Wprowadź kod pocztowy",
              })
            ),
            street: yup.string().required(
              intl.formatMessage({
                id: "addressDetailsForm_requiredFieldStreet",
                defaultMessage: "Wprowadź ulicę",
              })
            ),
            house_no: yup.string().required(
              intl.formatMessage({
                id: "addressDetailsForm_requiredFieldHouseNo",
                defaultMessage: "Wprowadź numer budynku",
              })
            ),
            apartment_no: yup.string(),
            additional_info: yup.string(),
          })
          .required(),
        delivery_date: yup
          .string()
          .transform((value, originalValue) =>
            originalValue ? format(new Date(value), "yyyy-MM-dd") : null
          )
          .nullable(),
        shipping_method_id: yup
          .number()
          .required(
            intl.formatMessage({
              id: "addressDetailsForm_requiredFieldShippingMethod",
              defaultMessage: "Wprowadź metodę wysyłki",
            })
          )
          .transform((value, originalValue) =>
            originalValue ? parseInt(value) : null
          ),
        payment_method_id: yup
          .number()
          .required(
            intl.formatMessage({
              id: "addressDetailsForm_requiredFieldPaymentsMethod",
              defaultMessage: "Wprowadź metodę płatności",
            })
          )
          .transform((value, originalValue) =>
            originalValue ? parseInt(value) : null
          ),
      }),
    [intl]
  )

  return (
    <Box my={6}>
      <Card className="order-card">
        <CardContent>
          <Box mb={6}>
            <h3>
              <Box component="span" mr={3}>
                <Truck></Truck>
              </Box>
              <FormattedMessage
                id="shipmentForm_title"
                defaultMessage="Dostawa i płatność"
              />
            </h3>
          </Box>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              handleSubmitting(values, validationSchema.cast(values))
              actions.setSubmitting(false)
            }}
            initialValues={values}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              isSubmitting,
              values,
              errors,
              touched,
              dirty,
            }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <AddressDetailsFormPart
                      errors={errors}
                      touched={touched}
                      dirty={dirty}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    ></AddressDetailsFormPart>
                  </Grid>
                </Grid>
                <Box my={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ShippingMethodFormPart
                        shippingMethods={shippingMethods}
                        errors={errors}
                        touched={touched}
                        dirty={dirty}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      ></ShippingMethodFormPart>
                    </Grid>
                  </Grid>
                </Box>
                <Box my={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <PaymentMethodFormPart
                        paymentMethods={paymentMethods}
                        errors={errors}
                        touched={touched}
                        dirty={dirty}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      ></PaymentMethodFormPart>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CircularButton
                    onClick={handleAbort}
                    direction="left"
                  ></CircularButton>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage
                      id="shipmentForm_btnNext"
                      defaultMessage="Dalej"
                    />
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  )
}

ShipmentForm.propTypes = {
  handleSubmitting: PropTypes.func.isRequired,
  handleAbort: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default ShipmentForm
