import React, { useMemo } from "react"
import PropTypes from "prop-types"
import PersonalDataFormPart from "./personalDataFormPart"
import EnvironmentFormPart from "./environmentFormPart"
import EmergancyContactFormPart from "./emergencyContactFormPart"
import { PersonFill } from "react-bootstrap-icons"
import CircularButton from "../../../components/circularButton"

import { Formik, Form } from "formik"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

import * as yup from "yup"
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input"
import { format } from "date-fns"

const axios = require(`axios`)

yup.addMethod(yup.string, "phone", function(errorMsg) {
  return this.test("validate-phone", errorMsg, function(value) {
    return (
      !value ||
      (isValidPhoneNumber(value) === true && isPossiblePhoneNumber(value))
    )
  })
})

yup.addMethod(yup.string, "emailAvailable", function(errorMsg) {
  return this.test({
    name: "validate-email-availablity",
    message: errorMsg,
    test: async function(value) {
      if (value) {
        try {
          const response = await axios.get(
            `${process.env.GATSBY_API_URL}/user/registration/check/email?email=${value}`
          )

          return response.data.available
        } catch (error) {
          return false
        }
      } else {
        return false
      }
    },
  })
})

yup.addMethod(yup.object, "optional", function(
  isOptional = true,
  defaultValue = null
) {
  return this.transform(function(value) {
    if (!isOptional) return value

    if (
      value &&
      Object.values(value).some(
        v => !(v === null || v === undefined || v === "")
      )
    ) {
      return value
    }

    return defaultValue
  }).default(defaultValue)
})

const PatientForm = props => {
  const intl = useIntl()
  const { handleSubmitting, handleAbort, values } = props

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(
          intl.formatMessage({
            id: "patientForm_requiredFieldName",
            defaultMessage: "Wprowadź imię",
          })
        ),
        surname: yup.string().required(
          intl.formatMessage({
            id: "patientForm_requiredFieldSurname",
            defaultMessage: "Wprowadź nazwisko",
          })
        ),
        email: yup
          .string()
          .required(
            intl.formatMessage({
              id: "patientForm_requiredFieldEmail",
              defaultMessage: "Wprowadź adres email",
            })
          )
          .email(
            intl.formatMessage({
              id: "patientForm_formatFieldEmail",
              defaultMessage: "Wprowadź poprawny adres email",
            })
          )
          .emailAvailable(
            intl.formatMessage({
              id: "patientForm_availableFieldEmail",
              defaultMessage:
                "Adres email jest już zarejestrowany w naszym systemie",
            })
          ),
        phone: yup
          .string()
          .required(
            intl.formatMessage({
              id: "patientForm_requiredFieldPhone",
              defaultMessage: "Wprowadź numer telefonu",
            })
          )
          .phone(
            intl.formatMessage({
              id: "patientForm_formatFieldPhone",
              defaultMessage: "Wprowadź poprawny numer telefonu",
            })
          ),
        birth_date: yup
          .string()
          .required(
            intl.formatMessage({
              id: "patientForm_requiredFieldBirthdate",
              defaultMessage: "Wprowadź datę urodzenia",
            })
          )
          .transform((value, originalValue) =>
            originalValue ? format(new Date(value), "yyyy-MM-dd") : null
          )
          .nullable(),
        language_cd: yup.string().required(
          intl.formatMessage({
            id: "patientForm_requiredFieldLanguage",
            defaultMessage: "Wprowadź język",
          })
        ),
        environment: yup.object().shape({
          weight: yup
            .number()
            .positive()
            .transform((value, originalValue) =>
              originalValue === "" ? null : value
            )
            .nullable(),
          height: yup
            .number()
            .positive()
            .transform((value, originalValue) =>
              originalValue === "" ? null : value
            )
            .nullable(),
          past_pregnancies: yup.boolean().required(),
          children_no: yup.number().when("past_pregnancies", {
            is: true,
            then: yup
              .number()
              .required(
                intl.formatMessage({
                  id: "patientForm_requiredFieldPastPregnancies",
                  defaultMessage: "Wprowadź liczbę dzieci",
                })
              )
              .min(0)
              .nullable(),
            otherwise: yup
              .number()
              .transform((value, originalValue) => null)
              .nullable(),
          }),
          medication: yup
            .string()
            .transform((value, originalValue) =>
              originalValue?.trim() === "" ? null : value
            )
            .nullable(),
          risk_factors: yup
            .string()
            .transform((value, originalValue) =>
              originalValue?.trim() === "" ? null : value
            )
            .nullable(),
          info: yup
            .string()
            .transform((value, originalValue) =>
              originalValue?.trim() === "" ? null : value
            )
            .nullable(),
        }),
        emergency_person: yup
          .object()
          .shape({
            name: yup.string(),
            surname: yup.string().required(
              intl.formatMessage({
                id: "patientForm_requiredFieldName",
                defaultMessage: "Wprowadź nazwisko",
              })
            ),
            email: yup.string().email(
              intl.formatMessage({
                id: "patientForm_formatFieldEmail",
                defaultMessage: "Wprowadź poprawny adres email",
              })
            ),
            phone: yup
              .string()
              .required(
                intl.formatMessage({
                  id: "patientForm_requiredFieldPhone",
                  defaultMessage: "Wprowadź numer telefonu",
                })
              )
              .phone(
                intl.formatMessage({
                  id: "patientForm_formatFieldPhone",
                  defaultMessage: "Wprowadź poprawny numer telefonu",
                })
              ),
            kinship: yup.string().required(
              intl.formatMessage({
                id: "patientForm_requiredFieldKinship",
                defaultMessage: "Wprowadź pokrewieństwo",
              })
            ),
          })
          .optional()
          .nullable(),
      }),
    [intl]
  )

  return (
    <Box my={6}>
      <Card className="order-card">
        <CardContent>
          <Box component="h3" mb={6}>
            <Box component="span" mr={3}>
              <PersonFill></PersonFill>
            </Box>
            <FormattedMessage
              id="patientForm_title"
              defaultMessage="Dane pacjentki"
            />
          </Box>
          <Formik
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={(values, actions) =>
              handleSubmitting(
                values,
                validationSchema.cast(values)
              ).finally(() => actions.setSubmitting(false))
            }
            initialValues={values}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <PersonalDataFormPart></PersonalDataFormPart>
                    <EmergancyContactFormPart></EmergancyContactFormPart>
                  </Grid>
                  <Grid item lg={6}>
                    <EnvironmentFormPart values={values}></EnvironmentFormPart>
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CircularButton
                    onClick={handleAbort}
                    direction="left"
                  ></CircularButton>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage
                      id="patientForm_btnNext"
                      defaultMessage="Dalej"
                    />
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  )
}

PatientForm.propTypes = {
  handleSubmitting: PropTypes.func,
  handleAbort: PropTypes.func,
  values: PropTypes.object,
}

export default PatientForm
