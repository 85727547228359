import { useStaticQuery, graphql } from "gatsby"

export const useOrderDictionary = () => {
  const data = useStaticQuery(graphql`
    query MyPackageQuery {
      allLanguage {
        nodes {
          language_cd
          language_name
        }
      }
    }
  `)

  const languages = data.allLanguage.nodes

  return { languages }
}
