import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SnackbarProvider from "react-simple-snackbar"
import OrderBanner from "../sections/order/order-banner"
import LoadingIndicator from "../components/LoadingIndicator"

import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import { useIntl } from "gatsby-plugin-react-intl"

import plLocale from "date-fns/locale/pl"

const OrderPage = ({ data }) => {
  const intl = useIntl()
  const [locale, setLocale] = useState(plLocale)

  useEffect(() => {
    var code = intl.locale

    if (code.startsWith("en")) {
      code = "en-US"
    }

    if (code.startsWith("es")) {
      code = "es"
    }

    const importLocaleFile = async () => {
      const localeToSet = await import(`date-fns/locale/${code}/index.js`)
      setLocale(localeToSet.default)
    }

    importLocaleFile()
  }, [])

  return (
    <Layout footerCms={data.strapiFooter}>
      <LoadingIndicator></LoadingIndicator>
      <SEO
        lang={intl.locale}
        title={data.strapiOrder.seo.title}
        description={data.strapiOrder.seo.description}
        keywords={data.strapiOrder.seo.keywords}
      />
      <SnackbarProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <OrderBanner></OrderBanner>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </Layout>
  )
}

export const query = graphql`
  query Order($language: String) {
    strapiOrder(locale: { eq: $language }) {
      seo {
        title
        description
        keywords {
          tag
        }
      }
    }
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
  }
`

export default OrderPage
