import React from "react"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { useIntl } from "gatsby-plugin-react-intl"

const ShipmentSummary = props => {
  const intl = useIntl()
  const { shipmentDetails } = props

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldCountry",
            defaultMessage: "Kraj",
          })}
          value={shipmentDetails.address_details.country}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldCity",
            defaultMessage: "Miasto",
          })}
          value={shipmentDetails.address_details.city}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldPostalCode",
            defaultMessage: "Kod pocztowy",
          })}
          value={shipmentDetails.address_details.postal_code}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldStreet",
            defaultMessage: "Ulica",
          })}
          value={shipmentDetails.address_details.street}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldHouseNo",
            defaultMessage: "Nr budynku",
          })}
          value={shipmentDetails.address_details.house_no}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldApartmentNo",
            defaultMessage: "Nr lokalu",
          })}
          value={shipmentDetails.address_details.apartment_no}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={intl.formatMessage({
            id: "addressDetailsForm_lblFieldAdditionalInfo",
            defaultMessage: "Dodatkowe informacje",
          })}
          value={shipmentDetails.address_details.additional_info}
          fullWidth
          disabled
        />
      </Grid>
    </Grid>
  )
}

ShipmentSummary.propTypes = {
  shipmentDetails: PropTypes.object.isRequired,
}

export default ShipmentSummary
