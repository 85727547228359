import React from "react"
import PropTypes from "prop-types"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { useIntl } from "gatsby-plugin-react-intl"

const PatientSummary = props => {
  const intl = useIntl()
  const { patientDetails } = props

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "personalDataForm_lblFieldName",
            defaultMessage: "Imię",
          })}
          value={patientDetails.name}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "personalDataForm_lblFieldSurname",
            defaultMessage: "Nazwisko",
          })}
          value={patientDetails.surname}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "personalDataForm_lblFieldPhone",
            defaultMessage: "Numer telefonu",
          })}
          value={patientDetails.phone}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={intl.formatMessage({
            id: "personalDataForm_lblFieldEmail",
            defaultMessage: "Email",
          })}
          value={patientDetails.email}
          fullWidth
          disabled
        />
      </Grid>
    </Grid>
  )
}

PatientSummary.propTypes = {
  patientDetails: PropTypes.object.isRequired,
}

export default PatientSummary
