import React from "react"

import { Field } from "formik"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { TextField, Checkbox } from "formik-material-ui"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

const EnvironmentFormPart = props => {
  const intl = useIntl()
  const { values } = props
  return (
    <>
      <h4>
        <FormattedMessage
          id="environmentForm_title"
          defaultMessage="Wywiad środowiskowy"
        />
      </h4>

      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Field
                  component={Checkbox}
                  type="checkbox"
                  name="environment.past_pregnancies"
                />
              }
              label={intl.formatMessage({
                id: "environmentForm_lblFieldPastPregnancies",
                defaultMessage: "Wcześniejsze ciąże?",
              })}
            />
          </Grid>
          {values.environment.past_pregnancies ? (
            <Grid item xs={12} md={6}>
              <Field
                component={TextField}
                name="environment.children_no"
                type="number"
                label={intl.formatMessage({
                  id: "environmentForm_lblFieldChildrenNo",
                  defaultMessage: "Liczba dzieci",
                })}
                color="secondary"
                fullWidth
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="environment.weight"
              type="number"
              label={intl.formatMessage({
                id: "environmentForm_lblFieldWeight",
                defaultMessage: "Waga",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              component={TextField}
              name="environment.height"
              type="number"
              label={intl.formatMessage({
                id: "environmentForm_lblFieldHeight",
                defaultMessage: "Wzrost",
              })}
              color="secondary"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Field
              component={TextField}
              name="environment.medication"
              type="text"
              label={intl.formatMessage({
                id: "environmentForm_lblFieldMedication",
                defaultMessage: "Leczenie",
              })}
              color="secondary"
              multiline={true}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Field
              component={TextField}
              name="environment.risk_factors"
              type="text"
              label={intl.formatMessage({
                id: "environmentForm_lblFieldRiskFactors",
                defaultMessage: "Czynniki ryzyka",
              })}
              color="secondary"
              multiline={true}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Field
              component={TextField}
              name="environment.info"
              type="text"
              label={intl.formatMessage({
                id: "environmentForm_lblFieldInfo",
                defaultMessage: "Informacja dla lekarza",
              })}
              color="secondary"
              multiline={true}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EnvironmentFormPart
